.c-usp {
  background-color: $white;

  ul {
    margin: 0;
  }
  .c-icon {
    fill: $primary;
    width: 40px;
    height: 40px;
  }
  li {
    list-style-type: none;
    @include interpolate(
      font-size,
      map-get($breakpoints, xs),
      map-get($breakpoints, xl),
      $h3-font-size-min,
      $h3-font-size-max
    );
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
