/*
 * FOOTER
 */

.c-footer {
    width: 100%;
    overflow: hidden;
    background-color: $dark;
    color: $white;
    line-height: 2em;

    h1, h2, h3, h4, h5, h6 {
        color: $white; 
    }

    .c-icon{
        fill: $white;
        width:30px; 
        height:30px;
    }

    p {
        color: $white;
        a{
            color: $white;
            text-decoration: underline;
        }
    }

    .c-footer-sub{
        background-color: #333333;
    }
}
