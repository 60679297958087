/*
 * BUTTONS
 */

.c-button {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: .625em 1.6em;
    background-color: $white;
    border-radius: 30px;
    transition: all .3s ease;
    color: $dark;
    text-decoration: none;
    text-align: center;
    font-weight:700;
    cursor: pointer;

    @include interpolate(
        font-size,
        map-get($breakpoints, xs),
        map-get($breakpoints, xl),
        1rem,
        1.2rem
    );

    input {
        background-color: transparent;
    }

    &:hover {
        background-color: lighten($light, 5%);
        text-decoration: none;
        color: $primary;
    }
}

.c-button-icon{
    .c-icon{
        fill: $primary;
    }
}

.c-button-small {
    padding: .25em .75em;
    font-size: .85em;
}

.c-button-underline{
    font-weight:bold;
    text-decoration:underline;
    transition: all 0.3s ease-out;
    &:hover{
        color: lighten($dark, 10%);
    }
}

.c-button-text{
    color: $primary;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    position: relative;

    &:after {
        content:"";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -8px;
        background-color: $primary;
        opacity: 0;
        transition: all .3s ease;
    }

    .c-icon {
        fill: $primary;
        width:20px;
        height:20px;
    }

    &:hover {

        &:after {
            opacity: 1;
        }
    }
}

.c-button-primary {
    background-color: $primary;
    color: $white;

    .c-icon {
        fill: $white;
    }

    &:hover {
        background-color: lighten($primary, 5%);
        color: $white;
    }
}

.c-button-border-primary {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;

    &:hover {
        background-color: $primary;
        color: $white;
        .c-icon {
            fill: $white;
        }
    }
}

.c-button-border-primary-icon {
    border-radius: 50%;
    border: 1px solid $primary;
    flex-shrink: 0;
    padding: .5em;

    &:hover {
        background-color: $primary;
        .c-icon {
            fill: $white;
        }
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: $tertiary;
    transition: all .3s ease;

    &:hover {
        background-color: $primary;
        color: $white;

        .c-icon {
            fill: $white;
        }
    }
}

.c-button-ghost {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;

    &:hover {
        background-color: $white;
        color: $primary;
        .c-icon{
            fill: $primary;
        }
    }
}

.c-button-video {
    width: 75px;
    height: 75px;
    background-color: $success;
    border-radius: 100%;
    padding: 12px;

    .c-icon {
        fill: $white;
        width: 51px;
        height: 51px;
        display: block;
    }

    &:hover {
        background-color: $primary;

        .c-icon {
            fill: $white;
        }
    }
}

.c-button-red {
    background-color: $error;
    color: $white;

    .c-icon {
        fill: $white;
    }

    &:hover {
        color: $white;
        background-color: lighten($error, 10);
    }
}

.c-button-green {
    background-color: $success;
    color: $white;

    .c-icon {
        fill: $white;
    }

    &:hover {
        color: $white;
        background-color: lighten($success, 10);
    }
}

.c-button-border-red {
    background-color: transparent;
    border: 1px solid $error;
    color: $error;

    &:hover {
        background-color: $error;
        color: $white;
        .c-icon {
            fill: $white;
        }
    }
}

.c-button-disabled {
    pointer-events: none;
    opacity: .33;
}

// Pagination

.c-button-pagination-prev,
.c-button-pagination-next {
    border-radius: 0;
    background-color: transparent;

    .c-icon {
        fill: $primary;
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: transparent;
    }
}

.c-button-pagination-number {
    border-radius: 50%;
    background-color: transparent;
}

.c-button-pagination-number-active {
    background-color: $white;
}

// Links

.c-link-chevron {
    font-weight: 700;
}

.c-link-chevron::after {
    border-style: solid;
    border-width: 0.085em 0.085em 0 0;
    content: '';
    display: inline-block;
    height: .5em;
    left: .5em;
    position: relative;
    top: .6em;
    transform: rotate(45deg);
    transition: all .15s ease;
    vertical-align: top;
    width: .5em;
}
