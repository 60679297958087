.c-faq {
  .c-faq-item {
    background-color: $white;
    margin-bottom: 1em;
    width: 100%;
    display: block;
    padding-bottom: 1em;
    border-bottom: 1px solid $grey;
  }
  .c-faq-item-title {
    padding: 0 1.6em; 
    cursor: pointer;
    background-color: $white;
    transition: all 0.3s ease-out;
    color: $primary;
    .c-icon {
      fill: $primary;
      transition: all 0.3s ease-out;
    }
    h4 {
      color: $primary;
      margin-bottom: 0;
      transition: all 0.3s ease-out;
    }
    &.c-faq-item-active {
      background-color: $light;
      padding: 1em 1.6em; 
      + .c-faq-item-content{
        background-color: $light;
      }
      .c-icon {
        transform: scaleY(-1);
      }
    }
  }
  .c-faq-item-content {
    padding: 0 1.6em 1.6em 1.6em;
    display: none;
  }
}
