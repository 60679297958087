/*
 * LISTS
 */

li {
    font-size: $base-font-size-max;
    position: relative;
}

.c-list-dropdown {
    padding: 10px;
    position: relative;

    p {
        color: $primary;
        font-weight: 700;
    }

    ul {
        list-style-type: none;
        margin: 0;
        width: 100%;

        li {
            background-color: $white;
        }
    }

    .c-list-dropdown-sub {
        background-color: $white;
        position: relative;
        white-space: nowrap;
        width: 100%;

        @include media-query(md) {
            width: 356px;
        }

        .c-list-dropdown-selected {
            padding: 10px;
            border: 1px solid $grey;
            border-radius: 5px;
            cursor: pointer;

            p {
                color: $tertiary;
            }

            &.active {
                .c-icon {
                    transform: rotateX(180deg);
                }
            }

            .c-icon {
                fill: $tertiary;
                transition: all 0.3s ease-out;
            }
        }

        .c-list-dropdown-items {
            position: absolute;
            border-radius: 0 0 5px 5px;
            border-bottom: 1px solid $grey;
            border-left: 1px solid $grey;
            border-right: 1px solid $grey;
            top: 45px;
            left: 0;
            z-index: 5;
            cursor: pointer;
            background-color: $white;

            a {
                transition: all 0.3s ease-out;
                cursor: pointer;
                padding: 16px 10px;
                display: block;
                text-decoration: none;
                color: $tertiary;
                font-weight: 700;

                &:hover {
                    background-color: $light;
                }
            }

            li {
                transition: all 0.3s ease-out;
                cursor: pointer;

                label {
                    cursor: pointer;
                    padding: 10px 10px;
                    display: block;
                }

                &:hover {
                    background-color: $light;
                }
            }

            input {
                display: none;
            }
        }
    }
}
