/*
 * Magnific Popup
 */

.c-mfp-content {
    background-color: $white;
    border-radius: $border-radius;
    position: relative;
    width: 100%;

    .flatpickr-calendar {
        border: 1px solid $tertiary
    }

    .c-validated {
        background-color: $success;
        display: inline-block;
        border-radius: 50%;
        padding: 10px;
        width: 70px;
        height: 70px;

        .c-icon {
            width: 50px;
            height: 50px;
        }
    }

}


