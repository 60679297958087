/*
 * HERO
 */
.c-hero-product{
  h1{
    color: $primary;
  }
}

.c-hero{
  img{
    width: 100%;
    height: auto;
    display: block;
  }
}