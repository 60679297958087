/*
 * ACCORDION
 */

.c-accordion-section {

    z-index: 1;

    @include media-query(lg) {
        margin-top: -20vh;
        padding-top: 20vh;
    }

    @include media-query(xl) {
        margin-top: -20vh;
        padding-top: 15vh;
    }
}

.c-accordion {

  span {
    width: 50px;
    height: 50px;
    margin-right: 2rem;
    display: block;
    padding: 0;
  }

  .c-accordion-item {

    h3, p{
      margin-bottom:0;
    }

    padding: 20px 0;

    &:last-child{
      //border-bottom: 1px solid $primary;
    }

    &:not(:first-child){
      .c-accordion-content{
        display: none;
      }
    }

    &.active {
      h3 {
        color: $white;
      }

      span {
        color: $tertiary;
        background-color: $secondary;
        border: 2px solid $secondary;
      }
    }
  }

  .c-accordion-title {

    h3 {
      color: $primary;
    }

    span {
      border: 2px solid $primary;
      border-radius: 50%;
      color: $primary;
      font-size: $h4-font-size-max;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-out;
    }
  }

  .c-accordion-content {
    overflow: hidden;

    p {
      color: $white;
    }

    span {
      visibility: hidden;
    }
  }

}
