.c-product-example {
  border: 3px solid $primary;
}

.c-product-total {
  font-weight: bold;
  @include interpolate(
    font-size,
    map-get($breakpoints, xs),
    map-get($breakpoints, xl),
    $h3-font-size-min,
    $h3-font-size-max
  );
}


.c-product{
    .c-button{
        width: 100%;
        text-align: center;
    }
}