.u-breakout {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
            }
        }
    }
}

.u-breakout-right {
    width: 100vw;
    position: relative;
    right: 0;
    margin-right: 0;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                width: 100vw;
                position: relative;
                right: 0;
                margin-right: 0;
            }
        }
    }
}

.u-breakout-img {

    @include media-query(lg) {
        width: 48%;
        position: relative;
        display: block;
        margin-bottom: 4em;
        max-height: 250px;
        img {
            position: relative;
            width: 100%;
            height: auto;
            max-height: 200px;
            display: block;
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 7%;
            left: 4%;
            display: block;
            background-color: $secondary;
            z-index: -1;
        }
    }

    &-right {

        @include media-query(lg) {
            width: 50%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            img {
                width: 100%;
                max-height: 960px;
                height: auto;
                display: block;
                object-fit: cover;
            }
            &:after {
                content: none;
            }
        }
    }
}

.u-breakout-none {
    width: auto;
    position: static;
    left: auto;
    right: auto;
    margin-left: 0;
    margin-right: 0;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                width: auto;
                position: static;
                left: auto;
                right: auto;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
