/*
 * SECTIONS
 */

// Home

.c-section-products{
  h3{
    color: $white;
  }

  .c-section-product{
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background-color: $white;
    display: flex;
    align-items:center;
    justify-content: center;

    img{
      height: 75px;
      width: auto;
      display: block;
    }
  }
}