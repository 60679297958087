.c-contact {
  .c-contact-content {
    z-index: 5;
    position: relative;
  }
  .c-contact-sidebar {
    background-color: $primary;
    z-index: 0;

    @include media-query(lg) { 
      margin-left: -200px;
      padding-left: 200px;
      height: 100%;

      .c-contact-sidebar-content {
        margin-left: 3em;
      }
    }
    
    p,
    a,
    h2 {
      color: $white;
    }
    .c-icon {
      fill: $white;
    }
  }
}
