/*
 * CARDS
 */

.c-card-grid {
  > .o-col {
    margin-bottom: $gutter;
  }
}

.c-card-round {
  img {
    border-radius: $border-radius;
  }
}

a {
  .c-card {
    transition: all 0.3s ease-out;
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0 8px rgba(0,0,0,0.3);
    }
  }
}

.c-card {
  position: relative;
  background: $white;
  transition: all 0.3s ease;
  border: 1px solid $grey;

  .c-card-box {
    h3,
    h4,
    p,
    span {
      transition: all 0.3s ease;
    }

    ul {
      li {
        transition: all 0.3s ease;
      }
    }
  }
}

.c-card-hover {
  &:hover {
    &.c-card-noborder {
      border: none;
    }

    box-shadow: 0 0 15px rgba(black, 0.1);
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;

    .c-button {
      background-color: $secondary;
      color: $tertiary;
    }

    .c-label-blue,
    .c-label-green {
      background-color: $white;
      color: $primary;
    }

    h3 {
      color: $white;
    }

    .c-card-box {
      h3,
      h4,
      p,
      span {
        color: $white;
      }

      ul {
        li {
          color: $white;
          border-bottom-color: lighten($tertiary, 10%);
        }
      }
    }

    img {
      opacity: 1;
    }

    .c-link-chevron::after {
      left: 1em;
    }
  }
}

.c-card-noborder {
  border: none;
}

.c-card-box {
  width: 100%;

  h4 {
    color: $tertiary;
  }

  p {
    font-size: 1.25rem;
    font-weight: 700;
    color: $tertiary;
    margin: 0;
    line-height: 115%;
    display: inline-block;

    @include media-query(md) {
      font-size: 2rem;
    }
  }

  span {
    font-size: $base-font-size-min;
    color: $tertiary;
    line-height: 100%;
    margin-left: 5px;
    text-align: right;
    font-weight: normal;
    display: inline-block;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      border-bottom: 1px solid $grey;
      color: $tertiary;
    }
  }
}

.c-card-text {
  border: 1px solid $primary;
}

.c-card-figure {
  position: relative;
  overflow: hidden;
  background-color: $dark;

  figcaption {
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    height: 100%;

    .u-text-container {
      width: 100%;
      height: 100%;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }
    }
  }
}

.c-card-figure-text {
  .c-card-date {
    color: $primary;
    font-weight: 700;
  }

  border: none;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.c-card-location {
  padding: 0;

  &:hover {
    box-shadow: 0 0 15px rgba(black, 0.1);
    border: 1px solid $primary;

    .c-button.c-button-secondary {
      background-color: $primary;
      color: $white;

      .c-icon {
        fill: $white;
      }
    }
  }
}

// Mijn Storo

.c-card-ms-box {
  border: 1px solid transparent;
  position: relative;
  background: $white;
  border-radius: $border-radius;

  h3,
  h4 {
    margin-bottom: 0;
  }

  h6 {
    color: $tertiary;
    text-transform: uppercase;
    font-size: 0.85rem;
  }

  p {
    color: $primary;
    font-weight: 700;
    margin-bottom: 0;
  }
}
