/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem, // 30rem
    sm: 640/16*1rem, // 40 rem
    md: 960/16*1rem, // 60 rem
    lg: 1024/16*1rem, // 64 rem
    xl: 1140/16*1rem, // 71.25 rem
    //xxl: 1440/16*1rem, // 71.25 rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem, // 0.5 rem
    sm: 16/16*1rem, // 1.0 rem
    md: 24/16*1rem, // 1.5 rem
    lg: 32/16*1rem, // 2.0 rem
    xl: 40/16*1rem, // 2.5 rem
    xxl: 64/16*1rem
);

// COLORS
$info: #000000;
$success: #50D26E;
$warning: #ffc107;
$error: #ED0040;

$dark: #1E2934;
$medium: #4A4A4A;
$grey: #D8D8D8;
$light: #f9f9f9; // rgba(238, 238, 238, 1)
$white: #ffffff;

$primary: #1B9DD9; // rgba(253, 153, 0, 1)
$secondary: #1E2934; // rgba(198, 3, 127, 1)
$tertiary: #00005A; // rgba(35, 125, 192, 1)

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 10px;

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');

$base-font-family: 'Nunito Sans', sans-serif;

$min: 1;

$base-font-family: $base-font-family;
$base-font-size-min: 1rem * $min;
$base-font-size-max: 1rem;
$base-font-weight: normal;
$base-line-height: 1.4;
$base-color: $dark;

$h1-font-family: $base-font-family;
$h1-font-size-min: 2.4rem * $min;
$h1-font-size-max: 2.8rem; //55px
$h1-font-weight: 700;
$h1-line-height: 1.15;
$h1-color: $dark;

$h2-font-family: $base-font-family;
$h2-font-size-min: 1.8rem * $min; 
$h2-font-size-max: 2rem; //44px
$h2-font-weight: 700;
$h2-line-height: 1.35;
$h2-color: $dark;

$h3-font-family: $base-font-family;
$h3-font-size-min: 1.2rem * $min;
$h3-font-size-max: 1.3rem; //30px
$h3-font-weight: 700;
$h3-line-height: 1.35;
$h3-color: $dark;

$h4-font-family: $base-font-family;
$h4-font-size-min: 1.25rem * $min;
$h4-font-size-max: 1.25rem;
$h4-font-weight: 700;
$h4-line-height: 1.35;
$h4-color: $primary;

$h5-font-family: $base-font-family;
$h5-font-size-min: 1.15rem * $min;
$h5-font-size-max: 1.15rem; //20px
$h5-font-weight: 100;
$h5-line-height: 1.35;
$h5-color: $tertiary;

$h6-font-family: $base-font-family;
$h6-font-size-min: 1rem * $min;
$h6-font-size-max: 1rem; //18px
$h6-font-weight: 10;
$h6-line-height: 1.35;
$h6-color: $tertiary;
