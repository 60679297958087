/*
 * NAVIGATION
 */

.body-overflow {
  height: 100%;
  overflow: hidden;

  .c-nav {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $primary;
    z-index: 100;
    height: auto;
    display: flex;
    flex-direction: column;

    .c-nav-top {
      display: flex;
      background-color: $light;
      z-index: 101;
    }

    .c-nav-main {
      display: flex;
      width: 100%;

      ul {
        width: 100%;
        li {
          width: 100%;
        }
      }

      .c-nav-button-title {
        font-size: 1.5rem;
        padding: 0.8em;
      }
    }

    .c-nav-toggle {
      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.c-nav {
  background-color: $white;
  position: relative;
  z-index: 50;

  .c-nav-logo {
    display: block;
    width: 400px;
    height: auto;
  }

  .c-nav-top {
    color: $medium;
    width: 100%;

    a {
      transition: all 0.3s ease-out;
      &:hover {
        color: $primary;
      }
    }

    .c-icon {
      fill: $primary;
      width: 26px;
      height: 26px;
    }

    @include media-query(xl) {
      position: relative;
      display: flex;
      border-bottom: 1px solid $grey;
      .c-nav-lang {
        border-left: 1px solid $grey;
        padding-left: 1em;
      }
    }
  }

  .c-nav-cart{
    background-color: $white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span{
      background-color: $error;
      font-size: 0.6rem;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      box-shadow: 0 0 4px rgba(0,0,0,0.6);
    }

    .c-icon{
      width: 24px;
      height: 24px;
      fill: $primary;
    }
  }

  .c-nav-cart-top{
    background-color: $primary;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    span{
      background-color: $error;
      font-size: 0.6rem;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      box-shadow: 0 0 4px rgba(0,0,0,0.6);
    }
    .c-icon{
      width: 24px;
      height: 24px;
      fill: $white;
    }
  }

  .c-nav-toggle {
    width: 30px;
    height: 25px;
    position: relative;
    background-color: transparent;

    &:focus{
      outline: none;
    }

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $white;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 10px;
    }

    span:nth-child(4) {
      top: 20px;
    }
  }

  .c-nav-contact, .c-nav-mobile{
    display: flex;
    @include interpolate(
        font-size,
        map-get($breakpoints, xs),
        map-get($breakpoints, xl),
        1.2rem,
        1.4rem
      ); 
    @include media-query(lg){
      display: none;
    }
  }

  .c-nav-contact{
    color: $white;
    border-top: 1px solid $white;
    padding-top: 5px;
  }

  .c-nav-main-wrapper {
    background-color: $primary;
    .c-nav-main-title {
      color: $white;
      font-weight: bold;
      @include interpolate(
        font-size,
        map-get($breakpoints, xs),
        map-get($breakpoints, xl),
        1.4rem,
        1.8rem
      ); 
    }
    .c-nav-main { 
      .c-nav-item {
        transition: all 0.4s ease-out;
        color: $white;
        text-decoration: none;
        margin-bottom: 1em;
        display: block;
        @include interpolate(
          font-size,
          map-get($breakpoints, xs),
          map-get($breakpoints, xl),
          2rem,
          1.4rem
        );
        
        @include media-query(lg){
          margin-bottom: 0;


        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid $white;
        }
        }
        
      }

      li {
        list-style-type: none;
        @include media-query(lg) {
          &:not(:last-child) {
            padding-left: 2rem;
            padding-right: 2rem;
            border-left: 2px solid $white;
          }
          &:last-child {
            padding-left: 2rem;
            border-left: 2px solid $white;
          }
        }
        @include media-query(xl) {
          &:not(:last-child) {
            padding-left: 3rem;
            padding-right: 3rem;
            border-left: 2px solid $white;
          }
          &:last-child {
            padding-left: 3rem;
            border-left: 2px solid $white;
          }
        }
      }
      
    }
  }
}
