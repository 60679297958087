/*
 * TABS
 */

.c-section-tabs {
    display: block;
    position: relative;

    &:before {
        z-index: 0;
        display: block;
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 50%;
        background-color: $primary;
    }

    .o-container {
        max-width: 100%;

        @include media-query(sm) {
            max-width: 60rem;
        }

        @include media-query(md) {
            max-width: 64rem;
        }

        @include media-query(lg) {
            max-width: 71.25rem;
        }
    }
}

.c-tabs-wrapper {
    width: 100%;
    position: relative;
    z-index: 4;
    background-color: $white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.c-tab-item-active,
.c-tab-item-success,
.c-tab-item-disabled {
    font-weight: 700;
    font-size: .95em;
    padding: 0 1em;

    @include media-query(md) {
        padding: 0;
        font-size: 1.25em;
        text-align: center;
    }
}

.c-tab-item-success {
    a {
        color: $success;

        .c-icon {
            border-radius: 50%;
            background-color: $success;
            fill: $white;
            border: 2px solid $success;
            width: 16px;
            height: 16px;
            display: block;

            @include media-query(md) {
                border: 4px solid $success;
                width: 32px;
                height: 32px;
            }
        }
    }
}

.c-tab-item-active {
    a {
        color: $primary;
    }
}

.c-tab-item-disabled {
    a {
        color: $grey;
    }
}
