/*
* FORMS
*/


.c-form-input-text,
.c-form-input-password,
.c-form-input-textarea {
    color: $primary;
    font-weight: 700;
    border: 1px solid transparent;
    background-color: $light;

    &:focus {
        border-color: $primary;
    }
}

.c-form-icon-radio {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid $medium;

    &:after {
        background-color: $primary;
        margin-top: -7px;
        margin-left: -7px;
        width: 14px;
        height: 14px;
    }
}

.c-form-icon-checkbox {
    height: 26px;
    width: 26px;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid $medium;

    &:after {

        fill: $primary;
    }
}

// Errors

.c-form-error {

    p {
        color: $error;
    }
}

.c-form-description {

    p {
        font-size: .75em;
    }
}

.c-form-group.hasError {

    .c-label {
        color: $error;
    }

    .c-form-input-text,
    .c-form-input-password,
    .c-form-input-textarea{
        border: 1px solid $error;
    }

    .c-form-input-select-container {
        border: 1px solid $error;
    }

    p.c-form-error {
        color: $error;
        font-size: .75em;
        margin-top: .5em;
    }
}
