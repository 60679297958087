.c-cart {
  .c-cart-item {
    border-bottom: 1px solid $grey;
    .c-icon {
      fill: $grey;
    }
  }
  .c-cart-list-title {
    font-weight: bold;
    margin-bottom: 0;
  }

  .c-cart-total {
    .c-cart-price {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid $dark;
      p{
        font-weight: bold;
        margin-bottom: 0;
        @include interpolate(
          font-size,
          map-get($breakpoints, xs),
          map-get($breakpoints, xl),
          1.2rem,
          1.4rem
        );
      }
     
    }
  }
}

#cart-delivery{
  display:none;
  &.visible{
    display: block;
  }
}
