/*
 * GENERAL ICON COLORS
 */

 .c-icon-wrapper{
     width:32px;
     height:32px;
 }

.c-icon-primary {
    fill: $primary;
}

.c-icon-secondary {
    fill: $secondary;
}

.c-icon-tertairy {
    fill: $tertiary;
}

.c-icon-white {
    fill: $white;
}

.c-icon-green {
    fill: $success;
}

.c-icon-red {
    fill: $error;
}

.c-icon-white-link {
    fill: rgba(255, 255, 255, 0.7);
}


// RATING COLORS

.c-icon-trustpilot-star-blank {
    fill: #DCDCE6;
}

.c-icon-trustpilot-star-success {
    fill: #00b67a;
}

.c-icon-trustpilot-star-mid {
    fill: #FFCE00;
}

.c-icon-trustpilot-star-error {
    fill: #FF3722;
}
