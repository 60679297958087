/*
 * BG
 */

 .u-bg-primary{
     background-color: $primary;
 }

.u-bg-light{
    background-color: $light;
}

.u-bg-white{
    background-color: $white;
}