/*
 * BODY WRAP
 */

.o-body {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    //overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: $white;
    transition: margin-left .3s ease-out;

    &.is-overlay {
        overflow: hidden;
        height: 100vh;

        .c-nav-mobile-content--overlay {
            pointer-events: auto;
        }
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($dark, .75);
        opacity: 0;
        position: absolute;
        z-index: 99;
        top: -100%;
        left: -100%;
        transition: opacity .3s ease-out, top 0s .3s, left 0s .3s;
    }

    &.is-pushed-right {
        margin-left: -248px;
        transition: margin-left .3s ease-out;
        overflow-y: hidden;

        &:before {
            opacity: 1;
            top: 0;
            left: 0;
            transition: opacity .3s ease-out;
        }
    }

    &.activeMobileMenu {
        margin-left: 250px;
    }
}
